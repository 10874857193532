<template>
    <div>
        <Loading v-if="$global.state.loading" />

        <div class="font-weight-bold">
            <div class="d-flex justify-space-between mb-2">
                <div>
                    <h5>
                        لوحة الاحصائيات
                        <span class="mr-2 grey--text">M A X</span>
                    </h5>
                    <small>مرحبًا بعودتك</small>
                </div>
                <div>
                    <v-card outlined color="transparent" class="border">
                        <v-icon class="ml-2" small color="primary">fi fi-br-calendar</v-icon>
                        <small class="primary--text">{{dateNow.toDateString()}}</small>
                    </v-card>
                </div>
            </div>

            <!-- clients -->
            <v-row>
                <v-col cols="12" md="3" v-for="(item, index) in clients" :key="index">
                    <v-card flat class="pa-5 rounded-lg font-weight-bold">
                        <div class="d-flex justify-space-between">
                            <div>
                                <div class="card-number mb-2">{{item.value}}</div>
                                <span v-text="item.title"></span>
                            </div>
                            <v-icon :color="item.color" x-large>{{ item.icon }}</v-icon>
                        </div>
                    </v-card>
                    <div
                        class="mx-5 mt-n2 rounded"
                        :class="$vuetify.theme.dark ? 'dark-statistic-card-footer' : 'statistic-card-footer'"
                    ></div>
                </v-col>
            </v-row>

            <v-card flat class="my-6" color="transparent">
                <h4 class="mb-3">احصائيات الطلبات</h4>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card class="shadow">
                            <v-card
                                v-for="(item, index) in orders"
                                :key="index"
                                class="px-6 py-3 mb-1 orders-card-shadow rounded-lg d-flex justify-space-between align-center"
                            >
                                <div>
                                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                    <span class="mr-3" v-text="item.title"></span>
                                </div>

                                <div>
                                    <span class="card-number-sm ml-1" v-text="item.value"></span>
                                    <v-icon :color="item.color" class="mt-n2">{{ item.subIcon }}</v-icon>
                                </div>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-card class="shadow pa-2">
                            <apexchart
                                type="bar"
                                height="312"
                                :options="chartOptions"
                                :series="series"
                            ></apexchart>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

            <v-row>
                <v-col cols="12" md="2" v-for="(item, index) in items" :key="index">
                    <v-card class="shadow rounded-xl">
                        <v-container fluid class="text-center py-8">
                            <v-icon
                                :color="item.color"
                                x-large
                                class="orange lighten-5 rounded-circle my-1 pa-3"
                            >{{ item.icon }}</v-icon>
                            <div>
                                <h2
                                    v-if="item.currency"
                                    class="card-number mt-4"
                                    :class="item.color + '--text'"
                                >{{ item.value | formatCurrency }}</h2>
                                <h2
                                    v-else
                                    class="card-number mt-4"
                                    :class="item.color + '--text'"
                                >{{ item.value }}</h2>
                                <div class="mt-2">{{item.title}}</div>
                            </div>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            dateNow: new Date(),
            clients: [
                {
                    title: "عدد الزبائن",
                    value: 0,
                    color: "primary",
                    icon: "fi fi-br-users",
                },
                {
                    title: "الزبائن الجدد",
                    value: 0,
                    color: "green lighten-2",
                    icon: "mdi-account-plus",
                },
                {
                    title: "الزبائن المميزين",
                    value: 0,
                    color: "blue lighten-1",
                    icon: "mdi-account-star",
                },
                {
                    title: "الزبائن المحظورين",
                    value: 0,
                    color: "error lighten-1",
                    icon: "no_accounts",
                },
            ],
            orders: [
                {
                    title: "عدد الطلبات",
                    value: 0,
                    color: "primary",
                    icon: "mdi-archive",
                    subIcon: "mdi-arrow-up-thin",
                },
                {
                    title: "الطلبات المنجزة",
                    value: 0,
                    color: "green lighten-2",
                    icon: "mdi-archive-check",
                    subIcon: "mdi-arrow-up-thin",
                },
                {
                    title: "الطلبات الملغاة",
                    value: 0,
                    color: "error",
                    icon: "mdi-archive-check",
                    subIcon: "mdi-arrow-down-thin",
                },
                {
                    title: "الطلبات من العروض",
                    value: 0,
                    color: "blue lighten-1",
                    icon: "mdi-archive-settings",
                    subIcon: "mdi-arrow-up-thin",
                },
                {
                    title: "الطلبات المكررة",
                    value: 0,
                    color: "info lighten-2",
                    icon: "mdi-archive-sync",
                    subIcon: "mdi-arrow-up-thin",
                },
                {
                    title: "الطلبات الراجعة",
                    value: 0,
                    color: "error lighten-1",
                    icon: "mdi-archive-remove",
                    subIcon: "mdi-arrow-down-thin",
                },
            ],

            series: [
                {
                    name: "عدد الطلبات",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
            ],
            chartOptions: {
                chart: {
                    height: 330,
                    type: "bar",
                    toolbar: {
                        show: true,
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: "45%",
                        borderRadius: 15,
                        distributed: true,
                        dataLabels: {
                            position: "top", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetY: 20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"],
                    },
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    categories: [
                        "كانون الثاني",
                        "شباط",
                        "آذار",
                        "نيسان ",
                        "أيار",
                        "حزيران",
                        "تموز ",
                        "آب",
                        "أيلول",
                        "تشرين الأول",
                        "تشرين الثاني",
                        "كانون الأول",
                    ],
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },

            items: [
                {
                    title: "العروض الفعالة",
                    value: 0,
                    color: "teal",
                    icon: "mdi-gift",
                    currency: false,
                },
                {
                    title: "عدد الاخبار",
                    value: 0,
                    color: "info",
                    icon: "fi fi-br-cloud-share",
                    currency: false,
                },
                {
                    title: "عدد المنتجات",
                    value: 0,
                    color: "grey",
                    icon: "fi fi-br-shopping-bag",
                    currency: false,
                },
                {
                    title: "عدد الشكاوي والاقتراحات",
                    value: 0,
                    color: "warning",
                    icon: "fi fi-br-shield-exclamation",
                    currency: false,
                },
                {
                    title: "عدد المستخدمين",
                    value: 0,
                    color: "brown",
                    icon: "fi fi-br-users-alt",
                    currency: false,
                },
                {
                    title: "إجمالي الطلبات",
                    value: 0,
                    color: "amber",
                    icon: "fi fi-sr-shopping-cart",
                    currency: true,
                },
            ],
        };
    },

    created() {
        this.getDashboard();
    },

    methods: {
        async getDashboard() {
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(`Dashboards`);

                this.clients[0].value = res.data.result.clientsCount;
                this.clients[1].value = res.data.result.newClientsCount;
                this.clients[2].value = res.data.result.fabClientsCount;
                this.clients[3].value = res.data.result.bloackClientsCount;

                this.orders[0].value = res.data.result.ordersCount;
                this.orders[1].value = res.data.result.doneOrdersCount;
                this.orders[2].value = res.data.result.canceledOrdersCount;
                this.orders[3].value = res.data.result.fromOffersOrdersCount;
                this.orders[4].value = res.data.result.duplicatedOrdersCount;
                this.orders[5].value = res.data.result.returnOrdersCount;

                (this.series = [
                    {
                        name: "عدد الطلبات",
                        data: res.data.result.ordersCountForYears,
                    },
                ]),
                    (this.items[0].value = res.data.result.offersCount);
                this.items[1].value = res.data.result.newsCount;
                this.items[2].value = res.data.result.productsCount;
                this.items[3].value =
                    res.data.result.complaintsSuggestionsCount;
                this.items[4].value = res.data.result.usersCount;
                this.items[5].value = res.data.result.totalOrders;
            } catch (err) {
                console.log(err);
            } finally {
                this.$global.state.loading = false;
            }
        },
    },
};
</script>



<style lang="scss" scoped>
.card-number {
    font-size: 28px;
    font-weight: 900;
    font-family: cursive;
    line-height: 1.2;
}
.card-number-sm {
    font-size: 20px;
    font-weight: 900;
    font-family: cursive;
}
.orders-card-shadow {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 16px 15px -20px !important;
}

.statistic-card-footer {
    height: 20px;
    z-index: -100 !important;
    background-color: #fff;
    opacity: 0.6;
}

.dark-statistic-card-footer {
    height: 20px;
    z-index: -100 !important;
    background-color: #162431;
    opacity: 0.3;
}
</style>
